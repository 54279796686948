<template>
  <div class="aboutWm">
    <div class="aboutNFTTitle">
      关于我们
      <img src="../assets/back.png" @click="goBack()" alt="" />
    </div>
    <!-- <div class="aboutWn-logo">
      <img src="../assets/cxLogo1.png" alt="">
    </div>
    <div class="ablutWm-Con" v-html="nftData.about_content"></div> -->
  </div>
</template>
<script>
import { getAboutWm } from "../api/person.js";

export default {
  setup() {},
  data() {
    return {
      nftData: {},
    };
  },
  mounted() {
    this.getNftData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getNftData() {
      getAboutWm().then((res) => {
        console.log(res.data);
        this.nftData = res.data;
      });
    },
  },
};
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
.aboutWm {
  .aboutNFTTitle {
    height: 40px;
    background: #ffffff;
    position: relative;
    font-size: 15px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      width: 16px;
      margin: auto;
    }
  }
  .aboutWn-logo{
    width: 142px;
    margin: 0 auto;
    margin-top: 20px;
    img{
      width: 100%;
    }
  }
  .ablutWm-Con{
    padding: 15px 15px;
    line-height: 12px;
  }
}
</style>